<template>
<v-container>
    <h1>User profile</h1>
</v-container>
</template>

<script>

export default {
  name: "Profile",
};
</script>
